
(function ($) {
    var plugin = this;
    plugin.settings = {}
    plugin.processingRequest = false;
    plugin.searchString = "";
    plugin.officeFilter = "";
    plugin.searchCategory = "";
    plugin.searchSubCategory = "";

    plugin.publicationType = "";
    plugin.publicationYear = "";

    plugin.displayValue = "";
    plugin.subDisplayValue = "";

    plugin.sector = "";
    plugin.practice = "";
    plugin.region = "";
    plugin.subSector = "";
    plugin.subService = "";
    plugin.subRegion = "";
    plugin.officeLocation = "";
    plugin.newsPubLocation = "";
    plugin.eventsLocation = "";
    plugin.filterGroup = "";
    plugin.contenttagid = "";
    plugin.enforcelanguage = "";
    plugin.languagetoenforce = "";

    plugin.loadCategories = false;

    plugin.currIndexValue = "";
    plugin.isSectorClicked = false;
    plugin.isServiceClicked = false;
    plugin.isRegionClicked = false;
    plugin.hideGroup = "";
    plugin.clickedGroup = "";

    plugin.loadFacets = true;

    plugin.datasource = {};

    plugin.pagingInfo = {};
    plugin.pagingInfo.currentPage = 1;
    plugin.pagingInfo.isLoadPagination = true;

    plugin.pagingInfo.pagingLastCount = "";
    plugin.pagingInfo.pagingFirstCount = "";
    plugin.pagingInfo.pagingNextCount = "";
    plugin.pagingInfo.pagingPreviousCount = "";

    plugin.pagingInfo.isLastClick = false;
    plugin.pagingInfo.isFirstClick = false;
    plugin.pagingInfo.isNextClick = false;
    plugin.pagingInfo.isPreviousClick = false;
    plugin.pagingInfo.isSortingClick = false;


    $.globalSearch = function (element, options) {
        /* defaults */
        var defaults = {
            pageNumber: 0,
            itemId: "",
            loaderDiv: "#divProcessing",
            honorGroupCount: true
        };

        function ResetPagingInfo() {
            plugin.pagingInfo.currentPage = 1;
            plugin.pagingInfo.isLoadPagination = true;
            plugin.pagingInfo.pagingLastCount = "";
            plugin.pagingInfo.pagingFirstCount = "";
            plugin.pagingInfo.pagingNextCount = "";
            plugin.pagingInfo.pagingPreviousCount = "";
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.isViewAllClick = false;

        };

        /* models */
        var searchresult = function (title, description, url, articledate, currentLanguage) {
            this.Title = title,
            this.Description = description,
            this.Url = url,
            this.ArticleDate = articledate;
            this.CurrentLanguage = currentLanguage;
        }

        var pagefacet = function (n, c, isLarge) {
            this.pageNo = n;
            this.applyCss = c;
            this.isLargeNumber = isLarge;

        }

        var category = function (name, count, datatitle, indexvalue, indexname) {
            this.FacetName = name,
            this.FacetCount = count,
            this.DataTitle = datatitle,
            this.IndexValue = indexvalue,
            this.IndexName = indexname;
        }


        /* init function */
        plugin.init = function () {

            plugin.settings = $.extend({}, defaults, options);

            var dataSeq = ['category', 'mypractice', 'location', 'subcategory', 'publicationtype', 'year'];
            $('#sectionSubCategory').hide();
            $('#sectionThirdLevel').hide();
            $('#sectionForthLevel').hide();

            //Get all query string paramaters
            var searchString = $.urlParam('SearchString');
            var siteContentCategory = $.urlParam('param_sitecontentcategory');
            var siteContentSubCategory = $.urlParam('param_sitecontentsubcategory');
            var sector = $.urlParam('param_sectors_sm');
            var practice = $.urlParam('param_practices_sm');
            var region = $.urlParam('param_region_sm');
            var officeLocation = $.urlParam('param_personofficeitem_sm');
            var newsPubLocation = $.urlParam('param_location_sm');
            var eventsLocation = $.urlParam('param_eventlocationsml_t');
            var subPractice = $.urlParam('param_sub_practices_sm');
            var subSector = $.urlParam('param_sub_sectors_sm');
            var subRegion = $.urlParam('param_sub_region_sm');
            var displayValue = $.urlParam('displayvalue');
            var subDisplayValue = $.urlParam('subdisplayvalue');
            var contenttagid = $.urlParam('content_tag_id');
            var enforcelanguage = $.urlParam('enforce_language');
            var languagetoenforce = $.urlParam('language_to_enforce');

            var filterGroup = $.urlParam('filtergroup');

            if (searchString == "0") {
                searchString = $.urlParam('searchString');
            }
            if (searchString != null && searchString != "") {
                $('#txtGlobalSearch').val(decodeURIComponent(searchString.split('+').join(' ')));
                $('#txtSiteSearch').val(decodeURIComponent(searchString.split('+').join(' ')));
                checkDefault("txtGlobalSearch");
            }

            if (siteContentCategory != null && siteContentCategory != '') {
                plugin.loadCategories = true;

                plugin.searchCategory = decodeURIComponent(siteContentCategory);
                if (plugin.searchCategory.toLowerCase() == "our people") {
                    $("#hBySubCategory").hide();
                } else {
                    $("#hBySubCategory").show();
                }
            }

            if (siteContentSubCategory != null && siteContentSubCategory != '') {
                plugin.searchSubCategory = decodeURIComponent(siteContentSubCategory.split('+').join(' '));
            }

            if (contenttagid != null && contenttagid != '') {
                plugin.contenttagid = decodeURIComponent(contenttagid);
            }

            if (enforcelanguage != null && enforcelanguage != '') {
                plugin.enforcelanguage = decodeURIComponent(enforcelanguage);
            }

            if (languagetoenforce != null && languagetoenforce != '') {
                plugin.languagetoenforce = decodeURIComponent(languagetoenforce);
            }

            if (sector != null && sector != '') {
                plugin.sector = sector;
            }


            if (filterGroup != null && filterGroup != '') {
                plugin.clickedGroup = filterGroup.toLowerCase();
            }

            if (subSector != null && subSector != '') {
                plugin.subSector = subSector;

            }

            if (subRegion != null && subRegion != '') {
                plugin.subRegion = subRegion;

            }

            if (practice != null && practice != '') {
                plugin.practice = practice;

            }

            if (region != null && region != '') {
                plugin.region = region;

            }

            if (officeLocation != null && officeLocation != "") {
                plugin.officeLocation = officeLocation;
            }

            if (newsPubLocation != null && newsPubLocation != "") {
                plugin.newsPubLocation = newsPubLocation;
            }

            if (eventsLocation != null && eventsLocation != "") {
                plugin.eventsLocation = eventsLocation;
            }

            if (subPractice != null && subPractice != '') {
                plugin.subService = subPractice;

            }

            if (displayValue != null && displayValue != "") {
                plugin.displayValue = decodeURIComponent(displayValue.split('+').join(' '));
            }

            if (subDisplayValue != null && subDisplayValue != "") {
                plugin.subDisplayValue = decodeURIComponent(subDisplayValue.split('+').join(' '));
            }
            SetPreviousData('source', '#source', false);
            SetPreviousData('locationtxt', '#locationtxt', false);
            var pagingNumber = 1;
            var pagingNumQS = $.urlParam('pagenum');
            if (pagingNumQS != 0) {
                setPagingInfoFromQS(pagingNumQS);
                pagingNumber = pagingNumQS;
                plugin.pagingInfo.currentPage = pagingNumber;
            }


            selectDdlFromQS();
            createSelectionAreaFromQSBackBtn();

            plugin.loadSearchResults1(pagingNumber, "", true);


            plugin.datasource.vm = {
                facets: ko.observableArray([]),
                subfacets: ko.observableArray([]),
                subfacetsThirdLevel: ko.observableArray([]),
                yearFacets: ko.observableArray([]),
                searchResults: ko.observableArray([]),
                total: ko.observable(""),
                /* if there spell check is true */
                searchResultCategory: ko.observable(""),
                searchText: ko.observable(""),
                /* for paging */
                pagingFacets: ko.observableArray([]),
                pageLastNo: ko.observable(""),
                pageFirstNo: ko.observable(""),
                loadPageData: plugin.loadPagingResult,
                PageFirstClick: plugin.pgFirstclick,
                PageLastClick: plugin.pgLastclick,
                PageNextClick: plugin.pgNextclick,
                PagePreviousClick: plugin.pgPreviousclick,
                PageViewAllClick: plugin.pgViewAllClick,
                PageBackToPaginationClick: plugin.pgBackToPaginationClick
            };

            $(document).on("click", ".siteCategory", function () {

                filterClick($(this));

                plugin.datasource.vm.searchResults.removeAll();
                plugin.pagingInfo.isLoadPagination = true;
                plugin.datasource.vm.pagingFacets.removeAll();
                plugin.datasource.vm.subfacets.removeAll();
                plugin.datasource.vm.subfacetsThirdLevel.removeAll();
                plugin.datasource.vm.yearFacets.removeAll();
                $("#sectionThirdLevel").hide();
                $("#sectionForthLevel").hide();

                plugin.loadFacets = true;
                plugin.searchCategory = $(this).attr('datatitle');

                plugin.searchSubCategory = "";
                plugin.loadCategories = false;
                ResetSortDDLOption();
                plugin.pagingInfo.isSortingClick = false;

                plugin.loadSearchResults(1, "");

                if (plugin.searchCategory.toLowerCase() == "our people") {
                    $("#hBySubCategory").hide();
                } else {
                    $("#hBySubCategory").show();
                }
            });

            $(document).on("click", ".siteSubCategory", function () {
                var name = $(this).attr('name');
                name = name.toLowerCase();

                plugin.publicationType = "";
                plugin.publicationYear = "";

                if ($(this).attr('parentGroup') != null) {
                    plugin.clickedGroup = $(this).attr('parentGroup').toLowerCase();
                } else {
                    plugin.clickedGroup = name;
                }

                if (name.indexOf("sector") >= 0) {
                    plugin.isSectorClicked = true;

                }
                else if (name.indexOf("service") >= 0) {
                    plugin.isServiceClicked = true;

                }
                else if (name.indexOf("region") >= 0) {
                    plugin.isRegionClicked = true;

                }

                filterClick($(this));

                plugin.datasource.vm.searchResults.removeAll();
                plugin.datasource.vm.subfacetsThirdLevel.removeAll();
                plugin.datasource.vm.yearFacets.removeAll();
                plugin.datasource.vm.pagingFacets.removeAll();
                plugin.loadFacets = true;

                plugin.pagingInfo.isLoadPagination = true;
                plugin.loadCategories = false;
                $("#sectionThirdLevel").hide();
                $("#sectionForthLevel").hide();
                if (plugin.searchCategory.toLowerCase() != "our people") {
                    plugin.searchSubCategory = $(this).attr('datatitle');

                }

                if ($(this).attr('parentid') != null) {
                    plugin.currIndexValue = $(this).attr("parentid");
                    plugin.filterGroup = $(this).attr('parentgroup');
                } else {
                    plugin.currIndexValue = $(this).attr("indexvalue");
                    plugin.filterGroup = $(this).data("sub");
                }


                ResetSortDDLOption();
                plugin.pagingInfo.isSortingClick = false;
                plugin.loadSearchResults(1, "");

            });

            $(document).on("click", ".thirdlevel", function () {

                filterClick($(this));
                plugin.publicationYear = "";
                plugin.datasource.vm.searchResults.removeAll();
                plugin.datasource.vm.pagingFacets.removeAll();
                plugin.datasource.vm.yearFacets.removeAll();
                plugin.loadFacets = true;
                plugin.pagingInfo.isLoadPagination = true;
                ResetSortDDLOption();
                plugin.pagingInfo.isSortingClick = false;
                plugin.loadSearchResults(1, "");
            });

            $(document).on("click", ".forthlevel", function () {

                filterClick($(this));
                plugin.datasource.vm.searchResults.removeAll();
                plugin.datasource.vm.pagingFacets.removeAll();
                plugin.loadFacets = true;
                plugin.pagingInfo.isLoadPagination = true;

                ResetSortDDLOption();
                plugin.pagingInfo.isSortingClick = false;
                plugin.loadSearchResults(1, "");
            });

            $(document).on("click", "span.crossBtn", function () {
                var dataType = $(this).attr('data-type');
                var currentIndexName = $(this).attr('indexname');
                var crossBtnGroup = $(this).parent().attr('class');
                var crossBtnParentId = $(this).attr('parentid');
                var crossBtnIndexValue = $(this).attr('indexvalue');
                var crossBtnParentGroup = $(this).attr('parentgroup');


                plugin.datasource.vm.searchResults.removeAll();
                plugin.pagingInfo.isLoadPagination = true;

                plugin.datasource.vm.pagingFacets.removeAll();
                if (dataType == "category") {
                    plugin.loadCategories = true;
                    plugin.searchCategory = "";
                    plugin.datasource.vm.facets.removeAll();
                    plugin.loadFacets = true;
                    $('span.crossBtn').each(function (i) {
                        $(this).removeAttr('indexname');
                    });

                    plugin.clickedGroup = "";

                } else {
                    $(this).removeAttr('indexname');
                }

                if (currentIndexName == "sitecontentsubcategory") {
                    plugin.datasource.vm.subfacets.removeAll();
                }

                plugin.searchSubCategory = "";
                plugin.isSectorClicked = false;
                plugin.isServiceClicked = false;
                plugin.isRegionClicked = false;
                FilterRemove(dataType);

                if (dataType == "category") {
                    SubGroupFilterRemove("subsector");
                    SubGroupFilterRemove("subservice");
                    SubGroupFilterRemove("subregion");
                }

                if (dataType == "year") {
                    plugin.publicationYear = "";
                }

                var dataCounttemp = $(this).attr('data-count-theme');
                $('.by-' + dataType + ' ul  a[id="' + dataCounttemp + '"]').parent().show();
                $(this).parent().remove();
                if ($('.grp' + dataType).find("li").length == 0)
                    $('.grp' + dataType).remove();
                check();
                var locationTextt = $('#locationtxt').val();
                if (locationTextt && locationTextt != '') {
                    var locationFilter = $('.grplocation ul li.Location');
                    if (!locationFilter || !locationFilter.length)
                    { $('#locationtxt').val(''); }
                }
                $('#sectionCategory').show();
                ResetSortDDLOption();
                plugin.loadFacets = true;
                plugin.pagingInfo.isSortingClick = false;

                if (crossBtnGroup != null && $('.selectedFilterVal > ul').find("ul").find("." + crossBtnGroup).length == 0
                && crossBtnGroup != null && $('.selectedFilterVal > ul').find("ul").find(".Sub" + crossBtnGroup).length == 0
                && crossBtnGroup != null && $('.selectedFilterVal > ul').find("ul").find("." + crossBtnGroup.replace("Sub", "")).length == 0) {

                    if (plugin.clickedGroup == crossBtnGroup.toLowerCase()
                     || plugin.clickedGroup == crossBtnGroup.substring(3).toLowerCase()) {
                        plugin.clickedGroup = "";
                    }
                }

                if (crossBtnGroup != null) {
                    if (crossBtnIndexValue != null) {
                        if (crossBtnGroup.toLowerCase() == "sector" || crossBtnGroup.toLowerCase() == "service"
                        || crossBtnGroup.toLowerCase() == "region") {
                            crossBtnParentId = crossBtnIndexValue;
                            crossBtnParentGroup = crossBtnGroup;
                        }
                    }

                    if (crossBtnParentId != null) {


                        var mainGroupSize = $('.selectedFilterVal > ul').find("ul").find("span" + '[indexvalue=' + crossBtnParentId + ']').length;

                        var subGroupSize = $('.selectedFilterVal > ul').find("ul").find("span" + '[parentid=' + crossBtnParentId + ']').length;

                        //Display subgroups in filter criteria if there is any sub group in selection criteria or if there is main group aviable and no sub group aviable in selection criteria
                        if ((mainGroupSize > 0 && subGroupSize >= 0) || (mainGroupSize == 0 && subGroupSize > 0)) {

                            plugin.currIndexValue = crossBtnParentId;

                            if (crossBtnParentGroup != null) {
                                if (crossBtnParentGroup.toLowerCase() == "sector") {
                                    plugin.isSectorClicked = true;

                                } else if (crossBtnParentGroup.toLowerCase() == "service") {
                                    plugin.isServiceClicked = true;

                                } else if (crossBtnParentGroup.toLowerCase() == "region") {
                                    plugin.isRegionClicked = true;

                                }
                            }
                        }
                    }
                }
                var subCategoryTypeClicktxt = '';
                var currentGrouptxt = '';
                var filtergroupTxt = '';
                var lastIndexName = '';
                var lastIndexValue = '';
                $('span.crossBtn').each(function (i) {
                    if ($(this).attr('indexname')) {
                        lastIndexName = $(this).attr('indexname');
                        lastIndexValue = $(this).attr('indexvalue');
                        var indexparentId = $(this).attr('parentid');
                        if (indexparentId)
                        { lastIndexValue = indexparentId; };

                        //  filters[indexName].push($(this).attr('indexvalue'));
                    }
                });
                if (lastIndexValue && lastIndexName) {

                    var isFound = false;
                    switch (lastIndexName) {
                        case 'sub_sectors_sm':
                        case 'sectors_sm':
                            isFound = true;
                            plugin.filterGroup = 'Sector';
                            break;
                        case 'sub_practices_sm':
                        case 'practices_sm':
                            isFound = true;
                            plugin.filterGroup = 'Service';
                            break;
                        case 'sub_region_sm':
                        case 'region_sm':
                            isFound = true;
                            plugin.filterGroup = 'Region';
                            break;
                        case 'personofficeitem_sm':
                            isFound = true;
                            plugin.filterGroup = 'Office';
                            break;

                    }
                    if (isFound) {
                        plugin.isSectorClicked = false;
                        plugin.isServiceClicked = false;
                        plugin.isRegionClicked = false;
                        plugin.currIndexValue = lastIndexValue;
                        switch (plugin.filterGroup) {
                            case 'Sector':
                                plugin.isSectorClicked = true;
                                break;
                            case 'Service':
                                plugin.isServiceClicked = true;
                                break;
                            case 'Region':
                                plugin.isRegionClicked = true;
                                break;
                        }
                    }
                }
                plugin.loadSearchResults(1, "");

            });

            $(document).on("click", ".clear-all-filter", function () {
                var dataType = $(this).attr('data-type');
                plugin.loadFacets = true;
                plugin.datasource.vm.searchResults.removeAll();
                plugin.datasource.vm.facets.removeAll();
                plugin.datasource.vm.pagingFacets.removeAll();
                plugin.datasource.vm.subfacets.removeAll();
                plugin.datasource.vm.subfacetsThirdLevel.removeAll();
                plugin.datasource.vm.yearFacets.removeAll();
                plugin.searchCategory = "";
                plugin.searchSubCategory = "";
                plugin.clickedGroup = "";
                plugin.isSectorClicked = false;
                plugin.isServiceClicked = false;
                plugin.isRegionClicked = false;
                plugin.currIndexValue = '';
                plugin.filterGroup = '';
                $("#refineResults").show();
                FilterRemove(dataType);
                $('#sectionCategory').show();
                ResetSortDDLOption();
                plugin.pagingInfo.isSortingClick = false;
                var searchString = plugin.searchString ? plugin.searchString : $.urlParam('SearchString');
                if (!searchString || searchString == '')
                { plugin.searchString = "*"; }
                $('#source').val('');
                $('#locationtxt').val('');
                $('#GlobleSearchSortingDiv').val('');
                plugin.loadSearchResults(1, "");
            });

            /* start */
            $(document).on("click", "#sectionSubCategory ul#ulPersonFacets li span.subCat", function () {
                if ($(this).hasClass("expand")) {
                    $(this).removeClass("expand");
                    $(this).parent().find("ul.subCatItem").slideUp();
                } else {

                    $("#sectionSubCategory ul#ulPersonFacets li span.subCat").removeClass("expand");
                    $("ul.subCatItem").hide();
                    $(this).parent().find("ul.subCatItem").slideDown();
                    $(this).addClass("expand");
                    $('html,body').scrollTop($(this).offset().top - 100);

                }
            });
            /* end */
            //This will be in use when user come from related resource section
            function createSelectionAreaFromQueryString() {
                var paramCategory = plugin.searchCategory; //$.urlParam('param_sitecontentcategory');
                if (paramCategory != null && paramCategory != '') {
                    paramCategory = decodeURIComponent(paramCategory);
                    plugin.searchCategory = paramCategory;
                    filterClick($('<a>', {
                        id: paramCategory.toLowerCase().replace(" ", ""), 'data-type': "category", 'name': "Category",
                        'indexname': 'sitecontentcategory', 'indexvalue': paramCategory, 'data-count-theme': paramCategory.toLowerCase().replace(" ", "")
                    }), decodeURIComponent(paramCategory));

                }

                var paramSubCategory = $.urlParam('param_sitecontentsubcategory');
                if (paramSubCategory != null && paramSubCategory != '') {
                    paramSubCategory = decodeURIComponent(paramSubCategory.split('+').join(' '));
                    plugin.searchSubCategory = paramSubCategory;
                    filterClick($('<a>', {
                        id: paramSubCategory.toLowerCase().replace(" ", ""), 'data-type': "subcategory", 'name': "Sub Category",
                        'indexname': 'sitecontentsubcategory', 'indexvalue': paramSubCategory, 'data-count-theme': paramSubCategory.toLowerCase().replace(" ", "")
                    }), decodeURIComponent(paramSubCategory));

                }



                if (plugin.sector != null && plugin.sector != '') {
                    var paramSector = decodeURIComponent(plugin.sector);
                    plugin.currIndexValue = plugin.sector;
                    plugin.isSectorClicked = true;
                    if (plugin.subSector == null || plugin.subSector == "") {
                        filterClick($('<a>', {
                            id: paramSector,
                            'data-type': "subcategory",
                            'data-sub': "Sector",
                            'name': "Sector",
                            'indexname': 'sectors_sm',
                            'indexvalue': paramSector,
                            'data-display': "m",
                            'data-count-theme': paramSector
                        }), plugin.displayValue);
                    }
                }

                if (plugin.practice != null && plugin.practice != '') {
                    var paramPractice = decodeURIComponent(plugin.practice);
                    plugin.isServiceClicked = true;
                    plugin.currIndexValue = plugin.practice;
                    if (plugin.subService == null || plugin.subService == "") {

                        filterClick($('<a>', {
                            id: paramPractice, 'data-type': "subcategory", 'data-sub': "Service", 'name': "Service",
                            'indexname': 'practices_sm', 'indexvalue': paramPractice, 'data-display': "m", 'data-count-theme': paramPractice
                        }), plugin.displayValue);
                    }
                }

                if (plugin.region != null && plugin.region != '') {
                    var paramRegion = decodeURIComponent(plugin.region);
                    plugin.isRegionClicked = true;
                    plugin.currIndexValue = plugin.region;
                    if (plugin.subRegion == null || plugin.subRegion == "") {
                        filterClick($('<a>', {
                            id: paramRegion, 'data-type': "subcategory", 'data-sub': "Region", 'name': "Region",
                            'indexname': 'region_sm', 'indexvalue': paramRegion, 'data-display': "m", 'data-count-theme': paramRegion
                        }), plugin.displayValue);
                    }
                }

                if (plugin.officeLocation != null && plugin.officeLocation != '') {
                    var paramOfficeLocation = decodeURIComponent(plugin.officeLocation);
                    plugin.currIndexValue = plugin.officeLocation;
                    filterClick($('<a>', {
                        id: paramOfficeLocation, 'data-type': "location", 'data-sub': "Office", 'name': "Office",
                        'indexname': 'personofficeitem_sm', 'indexvalue': paramOfficeLocation, 'data-display': "m", 'data-count-theme': paramOfficeLocation
                    }), plugin.displayValue);
                }

                if (plugin.newsPubLocation != null && plugin.newsPubLocation != '') {
                    var paramNewsPubLocation = decodeURIComponent(plugin.newsPubLocation);
                    plugin.currIndexValue = plugin.newsPubLocation;
                    filterClick($('<a>', {
                        id: paramNewsPubLocation, 'data-type': "location", 'data-sub': "Location", 'name': "Location",
                        'indexname': 'location_sm', 'indexvalue': paramNewsPubLocation, 'data-display': "m", 'data-count-theme': paramNewsPubLocation
                    }), plugin.displayValue);
                }

                if (plugin.eventsLocation != null && plugin.eventsLocation != '') {
                    var paramEventsLocation = decodeURIComponent(plugin.eventsLocation);
                    plugin.currIndexValue = plugin.eventsLocation;
                    filterClick($('<a>', {
                        id: paramEventsLocation, 'data-type': "location", 'data-sub': "Location", 'name': "Location",
                        'indexname': 'eventlocationsml_t', 'indexvalue': paramEventsLocation, 'data-display': "m", 'data-count-theme': paramEventsLocation
                    }), plugin.displayValue);
                }

                if (plugin.subSector != null && plugin.subSector != '') {
                    var paramSubSector = decodeURIComponent(plugin.subSector);
                    filterClick($('<a>', {
                        id: paramSubSector, 'data-type': "subsector", 'data-sub': "SubSector", 'name': "SubSector", 'data-display': "m",
                        'indexname': 'sub_sectors_sm', 'indexvalue': paramSubSector, 'data-count-theme': paramSubSector, 'parentgroup': plugin.clickedGroup
                    }), plugin.subDisplayValue);
                }

                if (plugin.subService != null && plugin.subService != '') {
                    var paramSubService = decodeURIComponent(plugin.subService);
                    filterClick($('<a>', {
                        id: paramSubService, 'data-type': "subservice", 'data-sub': "SubService", 'name': "SubService", 'data-display': "m",
                        'indexname': 'sub_practices_sm', 'indexvalue': paramSubService, 'data-count-theme': paramSubService, 'parentgroup': plugin.clickedGroup
                    }), plugin.subDisplayValue);
                }

                if (plugin.subRegion != null && plugin.subRegion != '') {
                    var paramSubRegion = decodeURIComponent(plugin.subRegion);
                    filterClick($('<a>', {
                        id: paramSubRegion, 'data-type': "subregion", 'data-sub': "SubRegion", 'name': "SubRegion", 'data-display': "m",
                        'indexname': 'sub_region_sm', 'indexvalue': paramSubRegion, 'data-count-theme': paramSubRegion, 'parentgroup': plugin.clickedGroup
                    }), plugin.subDisplayValue);
                }

            }

            function setPagingInfoFromQS(pagingNumQS) {
                var pagingchangeclickQS = $.urlParam('pagingchangeclick');
                if (pagingchangeclickQS != 0) {
                    switch (pagingchangeclickQS) {
                        case 'isLastClick':
                            plugin.pagingInfo.isLastClick = true;
                            break;
                        case 'isFirstClick':
                            plugin.pagingInfo.isFirstClick = true;
                            break;
                        case 'isNextClick':
                            plugin.pagingInfo.isNextClick = true;
                            plugin.pagingInfo.pagingNextCount = pagingNumQS;
                            break;
                        case 'isPreviousClick':
                            plugin.pagingInfo.isPreviousClick = true;
                            plugin.pagingInfo.pagingPreviousCount = pagingNumQS;
                            break;
                    }
                }
            }

            function SetPreviousData(qsField, txtJQueryField, IsEncoded) {
                var qsFieldValue = $.urlParam(qsField);
                if (qsFieldValue != 0) {
                    if (IsEncoded) { qsFieldValue = decodeURIComponent(qsFieldValue.split('+').join(' ')); }
                    $(txtJQueryField).val(qsFieldValue);
                }
            }

            function selectDdlFromQS() {

                //page size
                var pagesizeQS = $.urlParam('pagesize');
                if (pagesizeQS != 0) {
                    $("#ddlPageSize_Global").val(pagesizeQS);
                    var userTextPagesize = $("#ddlPageSize_Global option:selected").text();
                    $('#oldSelectedPageSize_Global').val($("#ddlPageSize_Global option:selected").val());
                    $(".sortSel .main #ulddlPageSize_Global .stylish-select-left .stylish-select-right").text(userTextPagesize);
                }
                else {
                    var ddlName = 'ddlPageSize_Global';
                    $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                    var userText = $('#' + ddlName).val();
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                    $('#oldSelectedPageSize_Global').val($('#' + ddlName + '').val());
                    $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                    $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
                }

                //var isRelatedLinkQs = $.urlParam('isRelatedLink');
                //if (isRelatedLinkQs != 0 && isRelatedLinkQs) {
                //    $('#isRelatedLink').val(isRelatedLinkQs);
                //    $("#generalOptions option[value='Title']").remove()
                //    $("#generalOptions").append(new Option('Position', 'Position'));
                //}
                var sortingqsDivQS = $.urlParam('sortingdiv');
                var ddlName = 'generalOptions';
                if (sortingqsDivQS && sortingqsDivQS != 0) {
                    ddlName = sortingqsDivQS;
                }


                var sortingqsQS = $.urlParam('sortingqs');
                if (sortingqsQS != 0) {
                    $('#GlobleSearchSortingDiv').val(ddlName);
                    sortingqsQS = decodeURIComponent(sortingqsQS);
                    $("#" + ddlName).val(sortingqsQS);
                    var userTextPagesize = $("#" + ddlName + " option:selected").text();
                    $('#GlobleSearchSortingValue').val($("#" + ddlName + " option:selected").val());
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userTextPagesize);
                }
                else {
                    //var ddlName1 = 'generalOptions';
                    $('#GlobleSearchSortingDiv').val(ddlName);
                    $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                    var userText = $('#' + ddlName).val();
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                    $('#GlobleSearchSortingValue').val($('#' + ddlName + '').val());
                    $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                    $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
                }
            }


            //This will be in use when user come from related resource section
            function createSelectionAreaFromQSBackBtn() {

                var catNameTxt = $.urlParam('catNameInfo');
                if (catNameTxt != 0 && catNameTxt != 0) {
                    catReadFromQS(catNameTxt);
                }

                var subCattxt1 = $.urlParam('subCatText1');
                var subCatInfo1 = $.urlParam('subCatInfo1');
                if (subCattxt1 != 0 && subCatInfo1 != 0) {
                    var arrSubCatinfo1 = subCatInfo1.split(',');
                    var arrSubCattxt1 = subCattxt1.split(',');
                    subCatReadFromQS1(arrSubCattxt1, arrSubCatinfo1);
                }


                //locationReadFromQS(locationDispTxt, locationIndexVal,locationIndexName)
                var locationDispTxtQS = $.urlParam('locationDispTxt');
                var locationIndexValQS = $.urlParam('locationIndexVal');
                var locationIndexNameQS = $.urlParam('locationIndexName');
                if (locationDispTxtQS != 0 && locationIndexValQS != 0 && locationIndexNameQS != 0) {
                    locationReadFromQS(locationDispTxtQS, locationIndexValQS, locationIndexNameQS);
                }
                var subCattxt = $.urlParam('subCatText');
                var subCatInfo = $.urlParam('subCatInfo');
                if (subCattxt != 0 && subCatInfo != 0) {
                    var arrSubCatinfo = subCatInfo.split(',');
                    var arrSubCattxt = subCattxt.split(',');
                    subCatReadFromQS(arrSubCattxt, arrSubCatinfo);
                }

                var subServiceText = $.urlParam('subServiceText');
                var subServiceInfo = $.urlParam('subServiceInfo');
                if (subServiceText != 0 && subServiceInfo != 0) {
                    var arrsubServiceInfo = subServiceInfo.split(',');
                    var arrsubServiceText = subServiceText.split(',');
                    AllSubPracticeReadFromQS(arrsubServiceText, arrsubServiceInfo, 'SubService', 'service')
                }

                var subSectorText = $.urlParam('subSectorText');
                var subSectorInfo = $.urlParam('subSectorInfo');
                if (subSectorText != 0 && subSectorInfo != 0) {
                    var arrsubSectorInfo = subSectorInfo.split(',');
                    var arrsubSectorText = subSectorText.split(',');
                    AllSubPracticeReadFromQS(arrsubSectorText, arrsubSectorInfo, 'SubSector', 'sector')
                }
                var subRegionText = $.urlParam('subRegionText');
                var subRegionInfo = $.urlParam('subRegionInfo');
                if (subRegionText != 0 && subRegionInfo != 0) {
                    var arrsubRegionInfo = subRegionInfo.split(',');
                    var arrsubRegionText = subRegionText.split(',');
                    AllSubPracticeReadFromQS(arrsubRegionText, arrsubRegionInfo, 'SubRegion', 'region')
                }

                var pubTypeInfo = $.urlParam('pubTypeInfo');
                if (pubTypeInfo != 0 && pubTypeInfo != 0) {
                    pubTypeReadFromQS(pubTypeInfo);
                }
                var pubYearInfo = $.urlParam('pubYearInfo');
                if (pubYearInfo != 0 && pubYearInfo != 0) {
                    pubYearReadFromQS(pubYearInfo);
                }
                //var locationDispTxt = $.urlParam('locationDispTxt');
                //var locationKeyValue = $.urlParam('locationKeyValue');
                //if (locationDispTxt != 0 && locationKeyValue != 0) {
                //    locationReadFromQS(locationDispTxt, locationKeyValue)
                //}

                var subCategoryTypeClickid = $.urlParam('subCategoryTypeClick');
                if (subCategoryTypeClickid != 0) {
                    switch (subCategoryTypeClickid) {
                        case 'isSectorClick':
                            plugin.isSectorClicked = true;
                            break;
                        case 'isServiceClick':
                            plugin.isServiceClicked = true;
                            break;
                        case 'isRegionClick':
                            plugin.isRegionClicked = true;
                            break;
                    }
                }
                var currentGroupId = $.urlParam('currentGroup');
                if (currentGroupId != 0) {
                    plugin.currIndexValue = currentGroupId;
                }
                var filterGroup = $.urlParam('filtergroup');
                if (filterGroup != 0 && filterGroup != '') {
                    plugin.clickedGroup = filterGroup.toLowerCase();
                    plugin.filterGroup = filterGroup;
                }

                //currIndexValue

                check();
            }

            function removeAllSpaces(inputVal) {
                if (inputVal) {
                    return inputVal.replace(/\s/g, "");
                }
                return inputVal;
            }

            function catReadFromQS(catTxt) {
                if (catTxt && catTxt.length > 0) {
                    catTxt = decodeURIComponent(catTxt.split('+').join(' '));
                    $('.selectedFilterVal >  ul').append('<li class="grpcategory"> <ul></ul> </li>');
                    $('.selectedFilterVal > ul .grpcategory ul').append('<li>Category : ' + catTxt + '<span indexname="sitecontentcategory" indexvalue="' + catTxt + '" class="crossBtn category" data-count-theme="' + removeAllSpaces(catTxt).toLowerCase() + '" data-type="category"></span></li>');
                }
            }

            function subCatReadFromQS(arrSubCatDispTxt, arrSubCatClassAndID) {
                if (arrSubCatDispTxt && arrSubCatDispTxt.length > 0) {
                    $('.selectedFilterVal >  ul').append('<li class="grpsubcategory"> <ul></ul> </li>');
                    for (var i = 0, j = 0; i < arrSubCatDispTxt.length;) {
                        var dispTxt = decodeURIComponent(arrSubCatDispTxt[i].split('+').join(' '));
                        var className = arrSubCatClassAndID[j];
                        var itemId = arrSubCatClassAndID[j + 1];
                        j += 2;
                        i++;
                        if (itemId) {
                            if (className) {
                                $('.selectedFilterVal > ul .grpsubcategory ul').append('<li class="' + className + '">' + dispTxt + '<span indexname="' + GetSubCatIndexnameFromClass(className) + '" indexvalue="' + itemId + '" class="crossBtn subcategory" data-count-theme="' + itemId + '" data-type="subcategory"></span></li>');
                            }
                            else {
                                var decodedval = decodeURIComponent(itemId);
                                $('.selectedFilterVal > ul .grpsubcategory ul').append('<li>' + dispTxt + '<span indexname="sitecontentsubcategory" indexvalue="' + decodedval + '" class="crossBtn subcategory" data-count-theme="' + decodedval.toLocaleLowerCase() + '" data-type="subcategory"></span></li>');
                            }
                        }
                    }
                }
            }

            function subCatReadFromQS1(arrSubCatDispTxt, arrSubCatClassAndID) {
                if (arrSubCatDispTxt && arrSubCatDispTxt.length > 0) {
                    $('.selectedFilterVal >  ul').append('<li class="grpmypractice"> <ul></ul> </li>');
                    for (var i = 0, j = 0; i < arrSubCatDispTxt.length;) {
                        var dispTxt = decodeURIComponent(arrSubCatDispTxt[i].split('+').join(' '));
                        var className = arrSubCatClassAndID[j];
                        var itemId = arrSubCatClassAndID[j + 1];
                        j += 2;
                        i++;
                        if (itemId) {
                            if (className) {
                                $('.selectedFilterVal > ul .grpmypractice ul').append('<li class="' + className + '">' + dispTxt + '<span indexname="' + GetSubCatIndexnameFromClass(className) + '" indexvalue="' + itemId + '" class="crossBtn mypractice" data-count-theme="' + itemId + '" data-type="mypractice"></span></li>');
                            }
                            else {
                                var decodedval = decodeURIComponent(itemId);
                                $('.selectedFilterVal > ul .grpmypractice ul').append('<li>' + dispTxt + '<span indexname="sitecontentsubcategory" indexvalue="' + decodedval + '" class="crossBtn mypractice" data-count-theme="' + decodedval.toLocaleLowerCase() + '" data-type="mypractice"></span></li>');
                            }
                        }
                    }
                }
            }

            function locationReadFromQS(locationDispTxt, locationIndexVal, locationIndexName) {
                if (locationDispTxt && locationIndexVal) {
                    locationIndexVal = decodeURIComponent(locationIndexVal.split('+').join(' '));
                    locationDispTxt = decodeURIComponent(locationDispTxt.split('+').join(' '));
                    $('.selectedFilterVal >  ul').append('<li class="grplocation"> <ul></ul> </li>');
                    $('.selectedFilterVal > ul .grplocation ul').append('<li class="Location">' + locationDispTxt + '<span indexname="' + locationIndexName + '" indexvalue="' + locationIndexVal + '" class="crossBtn location" data-count-theme="' + location + '" data-type="location"></span></li>');

                }
            }

            function AllSubPracticeReadFromQS(arrSubPracDispTxt, arrSubPracIdAndParentID, subPracticClass, parentGrp) {
                if (arrSubPracDispTxt && arrSubPracDispTxt.length > 0 && subPracticClass) {
                    var subPracticClassLower = subPracticClass.toLowerCase();
                    var liClassName = 'grp' + subPracticClassLower;
                    $('.selectedFilterVal > ul').append('<li class="' + liClassName + '"> <ul> </ul> </li>');
                    for (var i = 0, j = 0; i < arrSubPracDispTxt.length;) {
                        var dispTxt = decodeURIComponent(arrSubPracDispTxt[i].split('+').join(' '));
                        var itemId = arrSubPracIdAndParentID[j];
                        var parentId = arrSubPracIdAndParentID[j + 1];
                        j += 2;
                        i++;
                        $('.selectedFilterVal > ul .' + liClassName + ' ul').append('<li class="' + subPracticClass + '">' + dispTxt + '<span indexname="' + GetSubPracticeIndexnameFromClass(subPracticClass) + '" indexvalue="' + itemId + '" class="crossBtn ' + subPracticClassLower + '" data-count-theme="' + itemId + '" data-type="' + subPracticClassLower + '" parentid="' + parentId + '" parentgroup="' + parentGrp + '"></span></li>');
                    }
                }
            }

            function pubTypeReadFromQS(pubTypeTxt) {
                if (pubTypeTxt && pubTypeTxt.length > 0) {
                    pubTypeTxt = decodeURIComponent(pubTypeTxt.split('+').join(' '));
                    plugin.publicationType = pubTypeTxt.toUpperCase();
                    $('.selectedFilterVal >  ul').append('<li class="grppublicationtype"> <ul></ul> </li>');
                    $('.selectedFilterVal > ul .grppublicationtype ul').append('<li>Publication Type : ' + pubTypeTxt + '<span indexname="PublicationsTemplateName" indexvalue="' + pubTypeTxt + '" class="crossBtn publicationtype" data-count-theme="' + pubTypeTxt + '" data-type="publicationtype"></span></li>');
                }
            }

            function pubYearReadFromQS(pubYearTxt) {
                if (pubYearTxt && pubYearTxt.length > 0) {
                    $('.selectedFilterVal >  ul').append('<li class="grpyear"> <ul></ul> </li>');
                    $('.selectedFilterVal > ul .grpyear ul').append('<li>Year : ' + pubYearTxt + '<span indexname="PublicationYear" indexvalue="' + pubYearTxt + '" class="crossBtn year" data-count-theme="' + pubYearTxt + '" data-type="year"></span></li>');
                }
            }

            //function locationReadFromQS(locationDispText, locationIndexKeyVal) {
            //    if (locationDispText && locationDispText.length > 0) {
            //        var dispTxt = decodeURIComponent(locationDispText.split('+').join(' '));
            //        var indexKeyval = decodeURIComponent(locationIndexKeyVal.split('+').join(' '));
            //        if (indexKeyval) {
            //            var arrKeyval = indexKeyval.split(':');
            //            if (arrKeyval && arrKeyval.length > 1) {
            //                $('.selectedFilterVal >  ul').append('<li class="grplocation"> <ul></ul> </li>');
            //                $('.selectedFilterVal > ul .grplocation ul').append('<li class="Location">' + dispTxt + '<span indexname="' + arrKeyval[0] + '" indexvalue="' + arrKeyval[1] + '" class="crossBtn location" data-count-theme="' + arrKeyval[1] + '" data-type="location"></span></li>');
            //            }
            //        }
            //    }
            //}

            function GetSubCatIndexnameFromClass(className) {
                var returnval = '';
                if (className) {

                    switch (className) {
                        case 'Sector':
                            returnval = 'sectors_sm';
                            break;
                        case 'Service':
                            returnval = 'practices_sm';
                            break;
                        case 'Region':
                            returnval = 'region_sm';
                            break;
                        case 'Office':
                            returnval = 'personofficeitem_sm';
                            break;
                    }
                }
                return returnval;
            }

            function GetSubPracticeIndexnameFromClass(className) {
                var returnval = '';
                if (className) {

                    switch (className) {
                        case 'SubSector':
                            returnval = 'sub_sectors_sm';
                            break;
                        case 'SubService':
                            returnval = 'sub_practices_sm';
                            break;
                        case 'SubRegion':
                            returnval = 'sub_region_sm';
                            break;
                    }
                }
                return returnval;
            }


            function filterClick(currentObj, displayVal) {
                var currentObjId = currentObj.attr('indexvalue');
                var addInSelectionCriteria = true;
                $('span.crossBtn').each(function (i) {
                    var currValue = $(this).attr("indexvalue");
                    if (currValue == currentObjId) {
                        addInSelectionCriteria = false;
                        return;
                    }
                });
                if (addInSelectionCriteria == true) {
                    var name = currentObj.attr('name');

                    var dataTitle = currentObj.attr('datatitle');
                    var parentId = "";
                    parentId = currentObj.attr('parentId');

                    var parentGroup = "";
                    parentGroup = currentObj.attr('parentgroup');

                    var parentText = currentObj.attr('parentText');

                    var dataVal = '';
                    var dataType = currentObj.attr('data-type');
                    var datahtmlVal = currentObj.html();
                    var subStrindexPos = datahtmlVal.lastIndexOf('(');
                    var dataShowhtml = datahtmlVal;

                    if (dataType.toLowerCase() == "publicationtype") {
                        plugin.publicationType = dataTitle;
                    }

                    if (dataType.toLowerCase() == "year") {
                        plugin.publicationYear = dataType;
                    }

                    if (subStrindexPos > 0) {
                        dataShowhtml = datahtmlVal.substr(0, datahtmlVal.lastIndexOf('('));
                    }
                    if (displayVal != null && displayVal != '') {
                        dataVal = displayVal;
                        if (plugin.sector != null && plugin.sector) {
                            parentId = plugin.sector;
                        } else if (plugin.practice != null && plugin.practice) {
                            parentId = plugin.practice;
                        }
                        else if (plugin.region != null && plugin.region) {
                            parentId = plugin.region;
                        }
                    } else {
                        if (parentText != null && parentText != '') {
                            dataVal = parentText.replace(/_/g, ' ') + " : ";
                        }
                        dataVal = dataVal + dataShowhtml;
                    }


                    var dataId = currentObj.attr('id');
                    var indexName = "";
                    var indexValue = "";
                    if (currentObj.attr('indexname') != null && currentObj.attr('indexvalue') != null) {
                        indexName = currentObj.attr('indexname');
                        indexValue = currentObj.attr('indexvalue');
                    }

                    var displayMode = "s";
                    if (currentObj.attr('data-display')) {
                        displayMode = currentObj.attr('data-display');
                    }
                    if (displayMode == "s") {
                        $('.' + dataType).each(function () {
                            var dataCounttemp = $(this).attr('data-count-theme');
                            $('.by-' + dataType + ' ul  a[id="' + dataCounttemp + '"]').parent().show();
                            $(this).parent().remove();
                        });
                    }

                    if (displayVal == null || displayVal == '') {
                        FilterRemove(dataType, currentObj);
                    }

                    ///*for remove */

                    if ($('.selectedFilterVal > ul').find(".grp" + dataType).length == 0) {
                        var isAdded = false;
                        if (dataType == 'subcategory') {
                            if ($('.selectedFilterVal > ul').find(".grpcategory").length != 0) {
                                $('.selectedFilterVal > ul > .grpcategory').after("<li class='grp" + dataType + "'><ul></ul></li>");
                                isAdded = true;
                            }
                        }
                        if (!isAdded) {
                            $('.selectedFilterVal > ul').append("<li class='grp" + dataType + "'><ul></ul></li>");
                        }
                    }

                    var sub = "none";
                    if (currentObj.attr('data-sub')) {
                        sub = currentObj.attr('data-sub');
                    }

                    if (dataType == "subservice" || dataType == "subsector" || dataType == "subregion") {
                        name = "";
                    } else {
                        name = name.replace(":", " ");
                    }


                    if (sub != "none") {
                        if ($('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").find("." + sub).length == 0) {
                            if (name != null && name != '') {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li class="' + sub + '">' + name + ' : ' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
                            } else {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li class="' + sub + '">' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '" parentid="' + parentId + '" parentgroup="' + parentGroup + '"></span></li>');
                            }

                        } else if ($('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").find("." + sub).find("[indexvalue='" + indexValue + "']").length == 0) {
                            if (name != null && name != '') {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").find("." + sub + ":last").after('<li class="' + sub + '">' + name + ' : ' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
                            } else {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li class="' + sub + '">' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '" parentid="' + parentId + '" parentgroup="' + parentGroup + '"></span></li>');
                            }
                        }
                    } else {
                        $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li>' + name + ' : ' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
                    }

                    //To hide current item
                    currentObj.parent().hide();

                    check();
                    var locationTextt = $('#locationtxt').val();
                    if (locationTextt && locationTextt != '') {
                        var locationFilter = $('.grplocation ul li.Location');
                        if (!locationFilter || !locationFilter.length)
                        { $('#locationtxt').val(''); }
                    }

                }
            }

            function SubGroupFilterRemove(dataType) {
                $('.' + dataType).parent().remove();
                $('.grp' + dataType).remove();
                $('.by-' + dataType).find('ul li').remove();
                $('.by-' + dataType).hide();

            }

            function FilterRemove(dataType, currentObj) {
                /*for remove */
                var ctr = $.inArray(dataType, dataSeq);
                if (ctr > -1) {
                    for (var i = (dataSeq.length - 1) ; i > ctr; i--) {
                        var dataType1 = dataSeq[i];
                        $('.' + dataType1).each(function () {
                            $(this).parent().remove();
                        });
                        $('.grp' + dataType1).remove();
                        $('.by-' + dataType1).find('ul li').remove();
                        $('.by-' + dataType1).hide();
                    }
                }

                if (currentObj != null && currentObj.attr("parentgroup") == null) {
                    $('ul.subCatItem').each(function (i) {
                        $(this).hide();
                    });
                }
                var locationTextt = $('#locationtxt').val();
                if (locationTextt && locationTextt != '') {
                    var locationFilter = $('.grplocation ul li.Location');
                    if (!locationFilter || !locationFilter.length)
                    { $('#locationtxt').val(''); }
                }

                /*for remove */
            }
            $("#generalOptions").change(function () {
                SortOptionChange("#generalOptions");
            });
            $("#peopleOptions").change(function () {
                SortOptionChange("#peopleOptions");
            });
            $("#practiceOptions").change(function () {
                SortOptionChange("#practiceOptions");
            });
            $("#firmOptions").change(function () {
                SortOptionChange("#firmOptions");
            });
            $("#thinkingOptions").change(function () {
                SortOptionChange("#thinkingOptions");
            });
            $("#thinkingSubsectionOptions").change(function () {
                SortOptionChange("#thinkingSubsectionOptions");
            });

            $("#ddlPageSize_Global").change(function () {
                PageSizeChange("#ddlPageSize_Global");
            });


            function SortOptionChange(divId) {
                var oldSelectedval = $('#GlobleSearchSortingValue').val();
                if (oldSelectedval != $(divId).val()) {
                    $('#GlobleSearchSortingValue').val($(divId).val());
                    plugin.datasource.vm.searchResults.removeAll();
                    plugin.datasource.vm.pagingFacets.removeAll();
                    plugin.loadFacets = false;
                    plugin.pagingInfo.isLoadPagination = true;
                    plugin.pagingInfo.isSortingClick = true;
                    plugin.loadSearchResults(1, "");

                }
            }

            function PageSizeChange(divId) {
                var oldSelectedval = $('#oldSelectedPageSize_Global').val();
                if (oldSelectedval != $(divId).val()) {
                    $('#oldSelectedPageSize_Global').val($(divId).val());
                    plugin.datasource.vm.searchResults.removeAll();
                    plugin.datasource.vm.pagingFacets.removeAll();
                    plugin.loadFacets = false;
                    plugin.pagingInfo.isLoadPagination = true;
                    plugin.loadSearchResults(1, "");

                }
            }
            function ResetSortDDLOption() {
                var divs = "generalOptions,peopleOptions,practiceOptions,thinkingOptions,thinkingSubsectionOptions,firmOptions";
                var splitDivsArr = divs.split(',');
                $.each(splitDivsArr, function (index, value) {
                    /*console.log(index + ": " + value);*/

                    $("." + value).hide();
                    var divOfDll = "#" + value;

                    var userTextkey = $("#ul" + value + " ul.listing li:first").attr('data');
                    $("#ul" + value + " ul.listing li").removeAttr("class");
                    $("#ul" + value + " ul.listing li:first").attr("class", "selected");
                    $(divOfDll).prop('selectedIndex', 0);

                    var userText = $(divOfDll + " option:selected").text();
                    $('#' + value + 'Div' + " .main #ul" + value + " .stylish-select-left .stylish-select-right").text(userText);

                });
                $('#GlobleSearchSortingDiv').val("generalOptions");
                var userTextkey1 = $("#ulgeneralOptions ul.listing li:first").attr('data');
                $('#GlobleSearchSortingValue').val(userTextkey1);


            }

            function check() {
                var numItem = $('.selectedFilterVal ul li').length;
                numItem == 0 ? $('.selection-area').hide() : $('.selection-area').show();
            }
            ko.applyBindings(plugin.datasource.vm);

            ResetPagingInfo();


        }

        plugin.loadPagingResult = function (data, event) {
            plugin.datasource.vm.searchResults.removeAll();
            plugin.pagingInfo.isLoadPagination = false;
            plugin.loadFacets = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.isSortingClick = true;
            plugin.pagingInfo.currentPage = data.pageNo;
            plugin.loadSearchResults(data.pageNo, "");


        };


        // for first click
        plugin.pgFirstclick = function (data, event) {
            plugin.datasource.vm.searchResults.removeAll();
            plugin.pagingInfo.isLoadPagination = true;
            plugin.loadFacets = false;
            plugin.pagingInfo.isFirstClick = true;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.isSortingClick = true;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingFirstCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingFirstCount, "");
            plugin.pagingInfo.isFirstClick = false;
        };

        // for last click
        plugin.pgLastclick = function (data, event) {
            plugin.datasource.vm.searchResults.removeAll();
            plugin.pagingInfo.isLoadPagination = true;
            plugin.loadFacets = false;
            plugin.pagingInfo.isLastClick = true;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.isSortingClick = true;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingLastCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingLastCount, "");
            plugin.pagingInfo.isLastClick = false;
        };

        // for Next click
        plugin.pgNextclick = function (data, event) {
            plugin.datasource.vm.searchResults.removeAll();
            plugin.pagingInfo.isLoadPagination = true;
            plugin.loadFacets = false;
            plugin.pagingInfo.isNextClick = true;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.isSortingClick = true;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingNextCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingNextCount, "");
            plugin.pagingInfo.isNextClick = false;
        };

        // for Previous click
        plugin.pgPreviousclick = function (data, event) {
            plugin.datasource.vm.searchResults.removeAll();
            plugin.loadFacets = false;
            plugin.pagingInfo.isLoadPagination = true;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isPreviousClick = true;
            plugin.pagingInfo.isSortingClick = true;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingPreviousCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingPreviousCount, "");
            plugin.pagingInfo.isPreviousClick = false;
        };


        plugin.loadSearchResults = function (pagenum, groupId, isfirstLoad) {
            window.location = getResultPageUrl(pagenum, groupId);
            return;
            if (!plugin.processingRequest) {
                $(plugin.settings.loaderDiv).show();
                plugin.processingRequest = true;
                $.getJSON(getServiceUrl(pagenum, groupId), function (data) {
                    var isScrolled = false;
                    if (data.TotalRecords > 0) {
                        $("#globalsearchresult").show();
                        $("#searchHeader").show();
                        $("#refineResults").show();
                        if (isfirstLoad) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").addClass('hide');
                            $(".layout-mobile .related-source h1").removeClass('expand');
                        }
                        if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('hide')) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('hide').addClass('addHidePostProcessing');
                        }
                        plugin.datasource.vm.total(data.TotalRecords);
                        plugin.datasource.vm.searchText(data.SearchString);

                        plugin.searchString = data.SearchString;

                        if (plugin.loadFacets) {

                            var searchCategory = "";
                            if (plugin.searchCategory != "") {
                                searchCategory = plugin.searchCategory;
                            }

                            plugin.datasource.vm.searchResultCategory(searchCategory.toLowerCase());

                            if (data.PersonFacets != null) {
                                var personFacetData = eval(data.PersonFacets); // this will convert your json string to a javascript object
                                var strPersonFacet = "";

                                for (var key in personFacetData) {
                                    if (personFacetData.hasOwnProperty(key)) { // this will check if key is owned by data object and not by any of it's ancestors
                                        var keyContent = "";
                                        var hasKeyResult = false;

                                        var keyValue = '';
                                        if ($("#hid" + key) != null) {
                                            keyValue = $("#hid" + key).val();
                                        }

                                        keyContent = keyContent + "<li><span class='subCat' id='span" + key.toLowerCase() + "'>" + keyValue + "</span><ul id=ul" + key + " class='subCatItem'>";
                                        keyValue = keyValue.replace(/ /g, '_');

                                        $.each(personFacetData[key], function (i, f) {
                                            var facetName = f.Name.split(':')[0];
                                            var facetValue = f.Name.split(':')[1];
                                            var indexName = f.Name.split(':')[2];
                                            var parentText = facetName.replace(/ /g, '_');
                                            var currValue = "";

                                            if ((key == "Sector" && data.SubSectorFacets != null)
                                            || (key == "Service" && data.SubServiceFacets != null)
                                            || (key == "Region" && data.SubRegionFacets != null)) {
                                                var subFacetContentToCheck = null;
                                                var currValue1 = "";
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubSectorFacets; }
                                                else if (data.SubServiceFacets != null && key == "Service" && plugin.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubServiceFacets; }
                                                else if (data.SubRegionFacets != null && key == "Region" && plugin.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubRegionFacets; }
                                                var hasResult1 = false;
                                                if (subFacetContentToCheck) {
                                                    $.each(subFacetContentToCheck, function (j, g) {
                                                        currValue1 = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue1 = $(this).attr("indexvalue");
                                                            if (currValue1 == subFacetValue) {
                                                                subFacetValue = "";

                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult1 = true;
                                                        }
                                                    });
                                                }

                                                if (!hasResult1) {
                                                    $('span.crossBtn').each(function (i) {
                                                        currValue = $(this).attr("indexvalue");
                                                        if (currValue == facetValue) {
                                                            facetValue = "";

                                                        }
                                                    });
                                                }
                                            }
                                            else {
                                                $('span.crossBtn').each(function (i) {
                                                    currValue = $(this).attr("indexvalue");
                                                    if (currValue == facetValue) {
                                                        facetValue = "";
                                                        return false;
                                                    }
                                                });
                                            }
                                            // }

                                            if (facetValue != "") {
                                                var parentId = facetValue;
                                                keyContent = keyContent + "<li><a name=" + key + " id=" + facetValue + " indexname=" + indexName + " indexvalue=" + facetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subcategory' data-sub='" + key + "' data-display='m' datatitle=" + facetName.toUpperCase() + ">";
                                                keyContent = keyContent + facetName.toUpperCase() + " (" + f.AggregateCount + ")";
                                                keyContent = keyContent + "</a></li>";
                                                hasKeyResult = true;
                                                //alert(data.SubSectorFacets);
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin.currIndexValue == facetValue && plugin.isSectorClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubSectorFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubSector' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subsector' data-sub='SubSector' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }


                                                if (data.SubServiceFacets != null && key == "Service" && plugin.currIndexValue == facetValue && plugin.isServiceClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubServiceFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubService' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subservice' data-sub='SubService' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }

                                                if (data.SubRegionFacets != null && key == "Region" && plugin.currIndexValue == facetValue && plugin.isRegionClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubRegionFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubRegion' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subregion' data-sub='SubRegion' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }
                                            }

                                        });
                                        keyContent = keyContent + "</ul></li>";
                                        if (hasKeyResult == false) {
                                            keyContent = "";
                                        }
                                        strPersonFacet = strPersonFacet + keyContent;

                                    }
                                }

                                if (strPersonFacet != "") {
                                    $('#sectionSubCategory').show();
                                    $('#refineResults').show();
                                    $("#ulPersonFacets").html(strPersonFacet);

                                    if (plugin.searchCategory.toLowerCase() == "our people") {
                                        $('ul.subCatItem').each(function (i) {
                                            if (plugin.clickedGroup != null && plugin.clickedGroup != '') {
                                                if ($(this).attr("id").toLowerCase() == "ul" + plugin.clickedGroup) {
                                                    $("#ul" + plugin.clickedGroup).show();
                                                    $("#span" + plugin.clickedGroup).addClass("expand");
                                                } else {
                                                    $(this).hide();
                                                }
                                            } else {
                                                $(".subCatItem").hide();
                                            }
                                        });

                                    }

                                } else {
                                    $('#sectionSubCategory').hide();
                                    $('#refineResults').hide();

                                }

                            }

                            if (data.Facets != null) {
                                $('#sectionCategory').show();

                                $.each(data.Facets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin.datasource.vm.facets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });
                            }
                            else {
                                $('#sectionCategory').hide();
                            }


                            if (data.SubFacets != null) {
                                $('#sectionSubCategory').show();
                                $.each(data.SubFacets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin.datasource.vm.subfacets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });

                            }

                            if (data.SubFacetsThirdLevel != null) {
                                $('#sectionThirdLevel').show();
                                plugin.datasource.vm.subfacetsThirdLevel.removeAll();
                                $.each(data.SubFacetsThirdLevel, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        var indexname = "";
                                        if (plugin.searchSubCategory.toLowerCase() == "publication") {
                                            indexname = "PublicationsTemplateName";
                                        }
                                        plugin.datasource.vm.subfacetsThirdLevel.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), f.Name, indexname));
                                    }
                                });

                            }

                            if (data.YearFacets != null) {
                                $('#sectionForthLevel').show();
                                plugin.datasource.vm.yearFacets.removeAll();
                                $.each(data.YearFacets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }

                                    });

                                    if (addInCriteria == true) {
                                        plugin.datasource.vm.yearFacets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), f.Name, "PublicationYear"));
                                    }
                                });
                            }
                        }

                        if (data.PersonPaging.IsPaginationVisible) {
                            $('#pagingContainerSearchResults').show();
                            if (plugin.pagingInfo.isLoadPagination) {
                                //if exists then remove first
                                plugin.datasource.vm.pagingFacets.removeAll();
                                $.each(data.PersonPaging.PageNumbersFacets, function (i, f) {
                                    var isLarge = false;
                                    if (f > 99) {
                                        isLarge = true;
                                    }
                                    if (plugin.pagingInfo.currentPage == f) {

                                        plugin.datasource.vm.pagingFacets.push(new pagefacet(f, 2, isLarge));
                                    } else {
                                        plugin.datasource.vm.pagingFacets.push(new pagefacet(f, 0, isLarge));
                                    }

                                });

                                //set last and first page number
                                plugin.pagingInfo.pagingLastCount = data.PersonPaging.PageLastNumber;
                                plugin.pagingInfo.pagingFirstCount = data.PersonPaging.PageFirstNumber;

                                //set last and previous index
                                plugin.pagingInfo.pagingNextCount = data.PersonPaging.PageNextNumber;
                                plugin.pagingInfo.pagingPreviousCount = data.PersonPaging.PagePreviousNumber;

                                //for set next and previous cursor

                                if (data.PersonPaging.IsNextCursorVisible) {
                                    $('#pagNextBtn').show();
                                    $('#pagLastBtn').show();
                                } else {
                                    $('#pagNextBtn').hide();
                                    $('#pagLastBtn').hide();
                                }

                                if (data.PersonPaging.IsPreviousCursorVisible) {
                                    $('#pagFirstBtn').show();
                                    $('#pagPrevBtn').show();
                                } else {
                                    $('#pagFirstBtn').hide();
                                    $('#pagPrevBtn').hide();
                                }
                            }


                        } else {
                            $('#pagingContainerSearchResults').hide();
                        }
                        $.each(data.SearchResults, function (i, g) {
                            if (g.Title != null || g.Title != undefined) {
                                plugin.datasource.vm.searchResults.push(new searchresult(g.Title, g.Description, g.Url, g.ArticleDate, g.CurrentLanguage));
                            }

                        });
                        $("#divNoRecordFound").hide();
                        $(plugin.settings.loaderDiv).hide();


                    } else if (data.TotalRecords == 0) {
                        $(plugin.settings.loaderDiv).hide();
                        /* No records found -> show no records div */
                        var showNoSearchResultMessage = "Your search did not match any results. ";
                        $("#globalsearchresult").hide();
                        $("#divNoRecordFound").show();
                        if (data.SearchString) {
                            showNoSearchResultMessage = "Your search - " + data.SearchString + "  - did not match any results. ";
                        }
                        $("#divNoRecordFound").html($('#NoResultFound_Global').html());
                        $("#searchHeader").hide();

                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 230
                            });
                        }
                        isScrolled = true;
                    }
                    var oldVal = $('#GlobleSearchSortingDiv').val();
                    if (!plugin.pagingInfo.isSortingClick || (data.SortingFilterType && data.SortingFilterType != oldVal)) {
                        if (data.TotalRecords > 0) {
                            $('.global-search-sorting').show();
                            $('.global-search-sorting-per-page').show();
                        }
                        else {
                            $('.global-search-sorting').hide();
                            $('.global-search-sorting-per-page').hide();
                        }
                        plugin.pagingInfo.isSortingClick = false;
                        $('#GlobleSearchSortingDiv').val(data.SortingFilterType);
                        HideAllSortDdl();
                        var divOfDll = "#" + data.SortingFilterType;
                        $('#' + data.SortingFilterType + 'Div').removeClass('hide');

                        var userTextkey = $("#ul" + data.SortingFilterType + " ul.listing li:first").attr('data');
                        $('#GlobleSearchSortingValue').val(userTextkey);
                        $(divOfDll).prop('selectedIndex', 0);
                        var userText = $(divOfDll + " option:selected").text();
                        $(".allPositionddl .main #ulallPosition .stylish-select-left .stylish-select-right").text(userText);
                        $('#' + data.SortingFilterType + 'Div' + " .main #ul" + data.SortingFilterType + " .stylish-select-left .stylish-select-right").text(userText);
                    }

                    plugin.processingRequest = false;

                    if ($("#sectionCategory li:visible").length == 0) {
                        $("#sectionCategory").hide();
                    } else {
                        $("#sectionCategory").show();
                    }
                    if ($("#sectionSubCategory li:visible").length == 0) {
                        $("#sectionSubCategory").hide();
                    } else {
                        $("#sectionSubCategory").show();
                    }
                    if ($("#sectionThirdLevel li:visible").length == 0) {
                        $("#sectionThirdLevel").hide();
                    } else {
                        $("#sectionThirdLevel").show();
                    }
                    if ($("#sectionForthLevel li:visible").length == 0) {
                        $("#sectionForthLevel").hide();
                    } else {
                        $("#sectionForthLevel").show();
                    }

                    if (!($("#sectionCategory ul").has('li').length || $("#sectionSubCategory ul").has('li').length || $("#sectionThirdLevel ul").has('li').length || $("#sectionForthLevel ul").has('li').length)) {
                        $("#refineResults").hide();
                    } else {
                        $("#refineResults").show();

                    }

                    if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('addHidePostProcessing')) {
                        $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('addHidePostProcessing').addClass('hide');
                    }
                    if (!isScrolled) {
                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult').offset().top - 230
                            });
                        }
                    }

                    new mlMoment().init(); 

                });
            }
        }

        plugin.loadSearchResults1 = function (pagenum, groupId, isfirstLoad) {
            if (!plugin.processingRequest) {
                $(plugin.settings.loaderDiv).show();
                plugin.processingRequest = true;
                $.getJSON(getServiceUrl(pagenum, groupId), function (data) {
                    var isScrolled = false;
                    if (data.TotalRecords > 0) {
                        $("#globalsearchresult").show();
                        $("#searchHeader").show();
                        $("#refineResults").show();
                        if (isfirstLoad) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").addClass('hide');
                            $(".layout-mobile .related-source h1").removeClass('expand');
                        }
                        if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('hide')) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('hide').addClass('addHidePostProcessing');
                        }
                        plugin.datasource.vm.total(data.TotalRecords);
                        plugin.datasource.vm.searchText(data.SearchString);

                        plugin.searchString = data.SearchString;

                        if (plugin.loadFacets) {

                            var searchCategory = "";
                            if (plugin.searchCategory != "") {
                                searchCategory = plugin.searchCategory;
                            }

                            plugin.datasource.vm.searchResultCategory(searchCategory.toLowerCase());

                            if (data.PersonFacets != null) {
                                var personFacetData = eval(data.PersonFacets); // this will convert your json string to a javascript object
                                var strPersonFacet = "";

                                for (var key in personFacetData) {
                                    if (personFacetData.hasOwnProperty(key)) { // this will check if key is owned by data object and not by any of it's ancestors
                                        var keyContent = "";
                                        var hasKeyResult = false;

                                        var keyValue = '';
                                        if ($("#hid" + key) != null) {
                                            keyValue = $("#hid" + key).val();
                                        }

                                        keyContent = keyContent + "<li><span class='subCat' id='span" + key.toLowerCase() + "'>" + keyValue + "</span><ul id=ul" + key + " class='subCatItem'>";
                                        keyValue = keyValue.replace(/ /g, '_');

                                        $.each(personFacetData[key], function (i, f) {
                                            var facetName = f.Name.split(':')[0];
                                            var facetValue = f.Name.split(':')[1];
                                            var indexName = f.Name.split(':')[2];
                                            var parentText = facetName.replace(/ /g, '_');
                                            var currValue = "";

                                            if ((key == "Sector" && data.SubSectorFacets != null)
                                            || (key == "Service" && data.SubServiceFacets != null)
                                            || (key == "Region" && data.SubRegionFacets != null)) {
                                                var subFacetContentToCheck = null;
                                                var currValue1 = "";
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubSectorFacets; }
                                                else if (data.SubServiceFacets != null && key == "Service" && plugin.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubServiceFacets; }
                                                else if (data.SubRegionFacets != null && key == "Region" && plugin.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubRegionFacets; }
                                                var hasResult1 = false;
                                                if (subFacetContentToCheck) {
                                                    $.each(subFacetContentToCheck, function (j, g) {
                                                        currValue1 = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue1 = $(this).attr("indexvalue");
                                                            if (currValue1 == subFacetValue) {
                                                                subFacetValue = "";

                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult1 = true;
                                                        }
                                                    });
                                                }

                                                if (!hasResult1) {
                                                    $('span.crossBtn').each(function (i) {
                                                        currValue = $(this).attr("indexvalue");
                                                        if (currValue == facetValue) {
                                                            facetValue = "";

                                                        }
                                                    });
                                                }
                                            }
                                            else {
                                                $('span.crossBtn').each(function (i) {
                                                    currValue = $(this).attr("indexvalue");
                                                    if (currValue == facetValue) {
                                                        facetValue = "";
                                                        return false;
                                                    }
                                                });
                                            }
                                            // }

                                            if (facetValue != "") {
                                                var parentId = facetValue;
                                                keyContent = keyContent + "<li><a name=" + key + " id=" + facetValue + " indexname=" + indexName + " indexvalue=" + facetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subcategory' data-sub='" + key + "' data-display='m' datatitle=" + facetName.toUpperCase() + ">";
                                                keyContent = keyContent + facetName.toUpperCase() + " (" + f.AggregateCount + ")";
                                                keyContent = keyContent + "</a></li>";
                                                hasKeyResult = true;
                                                //alert(data.SubSectorFacets);
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin.currIndexValue == facetValue && plugin.isSectorClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubSectorFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubSector' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subsector' data-sub='SubSector' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }


                                                if (data.SubServiceFacets != null && key == "Service" && plugin.currIndexValue == facetValue && plugin.isServiceClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubServiceFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubService' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subservice' data-sub='SubService' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }

                                                if (data.SubRegionFacets != null && key == "Region" && plugin.currIndexValue == facetValue && plugin.isRegionClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubRegionFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubRegion' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subregion' data-sub='SubRegion' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }
                                            }

                                        });
                                        keyContent = keyContent + "</ul></li>";
                                        if (hasKeyResult == false) {
                                            keyContent = "";
                                        }
                                        strPersonFacet = strPersonFacet + keyContent;

                                    }
                                }

                                if (strPersonFacet != "") {
                                    $('#sectionSubCategory').show();
                                    $('#refineResults').show();
                                    $("#ulPersonFacets").html(strPersonFacet);

                                    if (plugin.searchCategory.toLowerCase() == "our people") {
                                        $('ul.subCatItem').each(function (i) {
                                            if (plugin.clickedGroup != null && plugin.clickedGroup != '') {
                                                if ($(this).attr("id").toLowerCase() == "ul" + plugin.clickedGroup) {
                                                    $("#ul" + plugin.clickedGroup).show();
                                                    $("#span" + plugin.clickedGroup).addClass("expand");
                                                } else {
                                                    $(this).hide();
                                                }
                                            } else {
                                                $(".subCatItem").hide();
                                            }
                                        });

                                    }

                                } else {
                                    $('#sectionSubCategory').hide();
                                    $('#refineResults').hide();

                                }

                            }

                            if (data.Facets != null) {
                                $('#sectionCategory').show();

                                $.each(data.Facets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin.datasource.vm.facets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });
                            }
                            else {
                                $('#sectionCategory').hide();
                            }


                            if (data.SubFacets != null) {
                                $('#sectionSubCategory').show();
                                $.each(data.SubFacets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin.datasource.vm.subfacets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });

                            }

                            if (data.SubFacetsThirdLevel != null) {
                                $('#sectionThirdLevel').show();
                                plugin.datasource.vm.subfacetsThirdLevel.removeAll();
                                $.each(data.SubFacetsThirdLevel, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        var indexname = "";
                                        if (plugin.searchSubCategory.toLowerCase() == "publication") {
                                            indexname = "PublicationsTemplateName";
                                        }
                                        plugin.datasource.vm.subfacetsThirdLevel.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), f.Name, indexname));
                                    }
                                });

                            }

                            if (data.YearFacets != null) {
                                $('#sectionForthLevel').show();
                                plugin.datasource.vm.yearFacets.removeAll();
                                $.each(data.YearFacets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }

                                    });

                                    if (addInCriteria == true) {
                                        plugin.datasource.vm.yearFacets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), f.Name, "PublicationYear"));
                                    }
                                });
                            }
                        }

                        if (data.PersonPaging.IsPaginationVisible) {
                            $('#pagingContainerSearchResults').show();
                            if (plugin.pagingInfo.isLoadPagination) {
                                //if exists then remove first
                                plugin.datasource.vm.pagingFacets.removeAll();
                                var pagingNumber = 1;
                                var pagingNumQS = $.urlParam('pagenum');
                                if (pagingNumQS != 0) { plugin.pagingInfo.currentPage = pagingNumQS; }

                                $.each(data.PersonPaging.PageNumbersFacets, function (i, f) {
                                    var isLarge = false;
                                    if (f > 99) {
                                        isLarge = true;
                                    }
                                    if (plugin.pagingInfo.currentPage == f) {
                                        plugin.datasource.vm.pagingFacets.push(new pagefacet(f, 2, isLarge));
                                    } else {
                                        plugin.datasource.vm.pagingFacets.push(new pagefacet(f, 0, isLarge));
                                    }

                                });

                                //set last and first page number
                                plugin.pagingInfo.pagingLastCount = data.PersonPaging.PageLastNumber;
                                plugin.pagingInfo.pagingFirstCount = data.PersonPaging.PageFirstNumber;

                                //set last and previous index
                                plugin.pagingInfo.pagingNextCount = data.PersonPaging.PageNextNumber;
                                plugin.pagingInfo.pagingPreviousCount = data.PersonPaging.PagePreviousNumber;

                                //for set next and previous cursor

                                if (data.PersonPaging.IsNextCursorVisible) {
                                    $('#pagNextBtn').show();
                                    $('#pagLastBtn').show();
                                } else {
                                    $('#pagNextBtn').hide();
                                    $('#pagLastBtn').hide();
                                }

                                if (data.PersonPaging.IsPreviousCursorVisible) {
                                    $('#pagFirstBtn').show();
                                    $('#pagPrevBtn').show();
                                } else {
                                    $('#pagFirstBtn').hide();
                                    $('#pagPrevBtn').hide();
                                }
                            }


                        } else {
                            $('#pagingContainerSearchResults').hide();
                        }
                        $.each(data.SearchResults, function (i, g) {
                            if (g.Title != null || g.Title != undefined) {
                                plugin.datasource.vm.searchResults.push(new searchresult(g.Title, g.Description, g.Url, g.ArticleDate, g.CurrentLanguage));
                            }

                        });
                        $("#divNoRecordFound").hide();
                        $(plugin.settings.loaderDiv).hide();


                    } else if (data.TotalRecords == 0) {
                        $(plugin.settings.loaderDiv).hide();
                        /* No records found -> show no records div */
                        var showNoSearchResultMessage = "Your search did not match any results. ";
                        $("#globalsearchresult").hide();
                        $("#divNoRecordFound").show();
                        if (data.SearchString) {
                            showNoSearchResultMessage = "Your search - " + data.SearchString + "  - did not match any results. ";
                        }
                        $("#divNoRecordFound").text(showNoSearchResultMessage);
                        $("#searchHeader").hide();

                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 230
                            });
                        }
                        isScrolled = true;
                    }
                    var oldVal = $('#GlobleSearchSortingDiv').val();
                    if (!plugin.pagingInfo.isSortingClick || (data.SortingFilterType && data.SortingFilterType != oldVal)) {
                        if (data.TotalRecords > 0) {
                            $('.global-search-sorting').show();
                            $('.global-search-sorting-per-page').show();
                        }
                        else {
                            $('.global-search-sorting').hide();
                            $('.global-search-sorting-per-page').hide();
                        }
                        plugin.pagingInfo.isSortingClick = false;
                        $('#GlobleSearchSortingDiv').val(data.SortingFilterType);
                        HideAllSortDdl();
                        var divOfDll = "#" + data.SortingFilterType;
                        $('#' + data.SortingFilterType + 'Div').removeClass('hide');

                        var userTextkey = $("#ul" + data.SortingFilterType + " ul.listing li:first").attr('data');
                        if (data.SortingFilterType && data.SortingFilterType != oldVal) {
                            $('#GlobleSearchSortingValue').val(userTextkey);
                            $(divOfDll).prop('selectedIndex', 0);
                            var userText = $(divOfDll + " option:selected").text();
                            $(".allPositionddl .main #ulallPosition .stylish-select-left .stylish-select-right").text(userText);
                            $('#' + data.SortingFilterType + 'Div' + " .main #ul" + data.SortingFilterType + " .stylish-select-left .stylish-select-right").text(userText);
                        }
                    }

                    plugin.processingRequest = false;

                    if ($("#sectionCategory li:visible").length == 0) {
                        $("#sectionCategory").hide();
                    } else {
                        $("#sectionCategory").show();
                    }
                    if ($("#sectionSubCategory li:visible").length == 0) {
                        $("#sectionSubCategory").hide();
                    } else {
                        $("#sectionSubCategory").show();
                    }
                    if ($("#sectionThirdLevel li:visible").length == 0) {
                        $("#sectionThirdLevel").hide();
                    } else {
                        $("#sectionThirdLevel").show();
                    }
                    if ($("#sectionForthLevel li:visible").length == 0) {
                        $("#sectionForthLevel").hide();
                    } else {
                        $("#sectionForthLevel").show();
                    }

                    if (!($("#sectionCategory ul").has('li').length || $("#sectionSubCategory ul").has('li').length || $("#sectionThirdLevel ul").has('li').length || $("#sectionForthLevel ul").has('li').length)) {
                        $("#refineResults").hide();
                    } else {
                        $("#refineResults").show();

                    }

                    if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('addHidePostProcessing')) {
                        $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('addHidePostProcessing').addClass('hide');
                    }
                    if (!isScrolled) {
                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult').offset().top - 230
                            });
                        }
                    }

                    new mlMoment().init();

                });
            }
        }

        var getServiceUrl = function (pagenum, groupId) {
            var url = "/api/custom/searchresults/execute?";

            var searchString = plugin.searchString ? plugin.searchString : $.urlParam('SearchString');

            var query = [];

            if (pagenum) {
                query.push("pagenum=" + pagenum);
            }
            var sortingDiv = $('#GlobleSearchSortingDiv').val();
            if (sortingDiv) {
                var sortingDivOption = $('#' + sortingDiv).val();
                query.push("sortingqs=" + sortingDivOption);
            }


            if (searchString) {
                query.push("searchString=" + searchString);
            }
            var SearchStringqsVal = $.urlParam('SearchString');
            var showEmptyMsg = false;
            if (SearchStringqsVal && SearchStringqsVal != 0 && SearchStringqsVal == '*') {

                $('#txtGlobalSearch').val($('#txtGlobalSearch').attr('defaultvalue').toLowerCase());
                $('#txtSiteSearch').val($('#txtSiteSearch').attr('defaultvalue').toLowerCase());
                checkDefault("txtGlobalSearch");
                showEmptyMsg = true;
            }
            if (plugin.pagingInfo.isLastClick) {
                query.push("isLastClick=true");
            }

            if (plugin.pagingInfo.isFirstClick) {
                query.push("isFirstClick=true");
            }

            if (plugin.pagingInfo.isNextClick) {
                query.push("isNextClick=true");
                query.push("pagingNextCount=" + plugin.pagingInfo.pagingNextCount);
            }

            if (plugin.pagingInfo.isPreviousClick) {
                query.push("isPreviousClick=true");
                query.push("pagingPreviousCount=" + plugin.pagingInfo.pagingPreviousCount);
            }
            if (plugin.pagingInfo.isViewAllClick) {
                query.push("isViewAllClick=true");
            }

            if (plugin.isSectorClicked) {
                query.push("isSectorClick=true");
            }


            if (plugin.isServiceClicked) {
                query.push("isServiceClick=true");
            }

            if (plugin.isRegionClicked) {
                query.push("isRegionClick=true");
            }

            if (plugin.currIndexValue != null && plugin.currIndexValue != "") {
                query.push("currentGroup=" + plugin.currIndexValue);
            }

            if (plugin.contenttagid != null && plugin.contenttagid != "") {
                query.push("content_tag_id=" + plugin.contenttagid);
            }

            if (plugin.enforcelanguage != null && plugin.enforcelanguage != "") {
                query.push("enforce_language=" + plugin.enforcelanguage);
            }

            if (plugin.languagetoenforce != null && plugin.languagetoenforce != "") {
                query.push("language_to_enforce=" + plugin.languagetoenforce);
            }

            if (plugin.publicationType != null && plugin.publicationType != "") {
                query.push("pubType=" + plugin.publicationType);
                showEmptyMsg = false;
            }

            if (plugin.publicationYear != null && plugin.publicationYear != "") {
                query.push("pubYear=" + plugin.publicationYear);
                showEmptyMsg = false;
            }

            if (plugin.loadCategories) {
                query.push("loadCategories=true");
            }

            if (plugin.searchCategory != null && plugin.searchCategory != "") {
                query.push("param_sitecontentcategory=" + plugin.searchCategory);
                showEmptyMsg = false;
            }

            if (plugin.searchSubCategory != null && plugin.searchSubCategory != "") {
                query.push("param_sitecontentsubcategory=" + plugin.searchSubCategory);
                showEmptyMsg = false;
            }
            if (showEmptyMsg) {
                $('span.crossBtn').each(function (i) {
                    if ($(this).attr('indexname') != null && $(this).attr('indexname') != "") {
                        showEmptyMsg = false;
                    }
                });
            }
            if (showEmptyMsg) {
                $('.no-text-msg').css("display", "block");
            }
            //for page size
            var pageSizeValue = $('#oldSelectedPageSize_Global').val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);

            }
            var locationtxtt = $('#locationtxt').val();
            if (locationtxtt && locationtxtt != '')
            { query.push("locationtxt=" + locationtxtt); }

            return url + query.join("&") + "&" + getFilterValues();

        };

        var getFilterValues = function () {
            var filters = {};

            $('span.crossBtn').each(function (i) {

                if ($(this).attr('indexname') != null && $(this).attr('indexname') != "") {
                    var indexName = $(this).attr('indexname');
                    if (!filters[indexName]) {
                        filters[indexName] = [];
                    }
                    filters[indexName].push($(this).attr('indexvalue'));
                }
            });
            flatendedValues = [];
            for (var filterName in filters) {
                flatendedValues.push(filterName + "=" + filters[filterName].join(","));
            }
            return flatendedValues.join("&");
        }

        var getResultPageUrl = function (pagenum, groupId) {
            var url = $("#txtGlobalSearch").closest("form").attr("action") + "?";


            var query = [];
            if (pagenum) {
                query.push("pagenum=" + pagenum);
            }
            var sortingDiv = $('#GlobleSearchSortingDiv').val();

            if (sortingDiv) {
                query.push("sortingdiv=" + sortingDiv);

                var sortingDivOption = $('#' + sortingDiv).val();
                query.push("sortingqs=" + sortingDivOption);
            }

            //for page size
            var pageSizeValue = $('#oldSelectedPageSize_Global').val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);

            }
            var searchString = plugin.searchString ? plugin.searchString : $.urlParam('SearchString');
            if (searchString) {
                query.push("SearchString=" + searchString);
            }

            if (plugin.pagingInfo.isLastClick) {
                query.push("pagingchangeclick=isLastClick");
            }
            else if (plugin.pagingInfo.isFirstClick) {
                query.push("pagingchangeclick=isFirstClick");
            }
            else if (plugin.pagingInfo.isNextClick) {
                query.push("pagingchangeclick=isNextClick");
            }
            else if (plugin.pagingInfo.isPreviousClick) {
                query.push("pagingchangeclick=isPreviousClick");
            }

            if (plugin.pagingInfo.isViewAllClick) {
                query.push("isViewAllClick=true");
            }

            if (plugin.isSectorClicked) {
                query.push("subCategoryTypeClick=isSectorClick");
            }
            else if (plugin.isServiceClicked) {
                query.push("subCategoryTypeClick=isServiceClick");
            }
            else if (plugin.isRegionClicked) {
                query.push("subCategoryTypeClick=isRegionClick");
            }
            if (plugin.currIndexValue != null && plugin.currIndexValue != "") {
                query.push("currentGroup=" + plugin.currIndexValue);
            }
            if (plugin.filterGroup != null && plugin.filterGroup != "") {
                query.push("filtergroup=" + plugin.filterGroup);
            }
            if (plugin.loadCategories) {
                query.push("loadCategories=true");
            }
            if (plugin.searchCategory != null && plugin.searchCategory != "") {
                query.push("param_sitecontentcategory=" + encodeURIComponent(plugin.searchCategory));
            }
            if (plugin.contenttagid != null && plugin.contenttagid != "") {
                query.push("content_tag_id=" + encodeURIComponent(plugin.contenttag));
            }
            if (plugin.enforcelanguage != null && plugin.enforcelanguage != "") {
                query.push("enforce_language=" + encodeURIComponent(plugin.enforcelanguage));
            }

            if (plugin.languagetoenforce != null && plugin.languagetoenforce != "") {
                query.push("language_to_enforce=" + encodeURIComponent(plugin.languagetoenforce));
            }


            if (plugin.searchSubCategory != null && plugin.searchSubCategory != "") {
                query.push("param_sitecontentsubcategory=" + encodeURIComponent(plugin.searchSubCategory));
            }
            $('.qsFieldsGlobal').each(function (i) {
                if ($(this).val() && $(this).val() != '')
                { query.push($(this).attr('id') + "=" + $(this).val()); }
            });
            return url + query.join("&") + "&" + getFilterValuesInQSGlobal();
        };

        var getFilterValuesInQSGlobal = function () {

            var catName = [];
            $('.grpcategory ul li span').each(function (i) {
                if ($(this).attr('indexvalue')) {
                    var CurrentCatName = $(this).attr('indexvalue');
                    catName.push(encodeURIComponent(CurrentCatName));
                }
            });

            var subCatClassAndID = [];
            var subCatDispTxt = [];
            $('.grpsubcategory ul li').each(function (i) {
                if ($(this).find('span').attr('indexvalue')) {
                    var classId;
                    if ($(this).attr('class')) {
                        classId = $(this).attr('class') + "," + $(this).find('span').attr('indexvalue');
                    }
                    else { classId = "," + encodeURIComponent($(this).find('span').attr('indexvalue')); }
                    subCatClassAndID.push(classId);
                    subCatDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var subCatClassAndID1 = [];
            var subCatDispTxt1 = [];
            $('.grpmypractice ul li').each(function (i) {
                if ($(this).find('span').attr('indexvalue')) {
                    var classId1;
                    if ($(this).attr('class')) {
                        classId1 = $(this).attr('class') + "," + $(this).find('span').attr('indexvalue');
                    }
                    else { classId = "," + encodeURIComponent($(this).find('span').attr('indexvalue')); }
                    subCatClassAndID1.push(classId1);
                    subCatDispTxt1.push(encodeURIComponent($(this).text()));
                }
            });


            var subServiceIDAndParentID = [];
            var subServicDispTxt = [];
            $('.grpsubservice ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var idAndParentID = $(this).find('span').attr('indexvalue') + "," + $(this).find('span').attr('parentid');
                    subServiceIDAndParentID.push(idAndParentID);
                    subServicDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var subSectorIDAndParentID = [];
            var subSectorDispTxt = [];
            $('.grpsubsector ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var idAndParentID = $(this).find('span').attr('indexvalue') + "," + $(this).find('span').attr('parentid');
                    subSectorIDAndParentID.push(idAndParentID);
                    subSectorDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var subRegionIDAndParentID = [];
            var subRegionDispTxt = [];
            $('.grpsubregion ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var idAndParentID = $(this).find('span').attr('indexvalue') + "," + $(this).find('span').attr('parentid');
                    subRegionIDAndParentID.push(idAndParentID);
                    subRegionDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var pubTypeVal = [];
            $('.grppublicationtype ul li span').each(function (i) {
                if ($(this).attr('indexvalue')) {
                    pubTypeVal.push(encodeURIComponent($(this).attr('indexvalue')));
                }
            });

            var pubyearval = [];
            $('.grpyear ul li span').each(function (i) {
                if ($(this).attr('indexvalue')) {
                    pubyearval.push($(this).attr('indexvalue'));
                }
            });

            var locationIndexName = [];
            var locationIndexVal = [];
            var locationDispTxt = [];
            $('.grplocation ul li').each(function (i) {
                if ($(this).find('span').attr('indexvalue')) {
                    var SpanItem = $(this).find('span');
                    // var classId;
                    var indexName = SpanItem.attr('indexname');
                    var indexVal = SpanItem.attr('indexvalue');
                    locationIndexName.push(indexName);
                    locationIndexVal.push(encodeURIComponent(indexVal));
                    locationDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var filters = {};

            if (catName.length > 0) {
                filters['catNameInfo'] = [];
                filters['catNameInfo'].push(catName);
            }
            if (subCatClassAndID.length > 0) {
                filters['subCatInfo'] = [];
                filters['subCatInfo'].push(subCatClassAndID);
            }
            if (subCatDispTxt.length > 0) {
                filters['subCatText'] = [];
                filters['subCatText'].push(subCatDispTxt);
            }
            if (subCatClassAndID1.length > 0) {
                filters['subCatInfo1'] = [];
                filters['subCatInfo1'].push(subCatClassAndID1);
            }
            if (subCatDispTxt1.length > 0) {
                filters['subCatText1'] = [];
                filters['subCatText1'].push(subCatDispTxt1);
            }
            if (subServiceIDAndParentID.length > 0) {
                filters['subServiceInfo'] = [];
                filters['subServiceInfo'].push(subServiceIDAndParentID);
            }
            if (subServicDispTxt.length > 0) {
                filters['subServiceText'] = [];
                filters['subServiceText'].push(subServicDispTxt);
            }
            if (subSectorIDAndParentID.length > 0) {
                filters['subSectorInfo'] = [];
                filters['subSectorInfo'].push(subSectorIDAndParentID);
            }
            if (subSectorDispTxt.length > 0) {
                filters['subSectorText'] = [];
                filters['subSectorText'].push(subSectorDispTxt);
            }
            if (subRegionIDAndParentID.length > 0) {
                filters['subRegionInfo'] = [];
                filters['subRegionInfo'].push(subRegionIDAndParentID);
            }
            if (subRegionDispTxt.length > 0) {
                filters['subRegionText'] = [];
                filters['subRegionText'].push(subRegionDispTxt);
            }

            if (pubTypeVal.length > 0) {
                filters['pubTypeInfo'] = [];
                filters['pubTypeInfo'].push(pubTypeVal);
            }
            if (pubyearval.length > 0) {
                filters['pubYearInfo'] = [];
                filters['pubYearInfo'].push(pubyearval);
            }
            if (locationDispTxt.length > 0) {
                filters['locationDispTxt'] = [];
                filters['locationDispTxt'].push(locationDispTxt);
            }
            if (locationIndexVal.length > 0) {
                filters['locationIndexVal'] = [];
                filters['locationIndexVal'].push(locationIndexVal);
            }
            if (locationIndexName.length > 0) {
                filters['locationIndexName'] = [];
                filters['locationIndexName'].push(locationIndexName);
            }

            flatendedValues = [];
            for (var filterName in filters) {
                flatendedValues.push(filterName + "=" + filters[filterName].join(","));
            }
            return flatendedValues.join("&");
        }

        plugin.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.globalSearch = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('globalSearch')) {
                var plugin = new $.globalSearch(this, options);
                $(this).data('globalSearch', plugin);
            }
        });
    }

    $.globalAutoSuggest = function (element, options) {
        /* defaults */
        var defaults = {
            itemId: "",
            viewMoreLabel: "",
            viewAllLabel: ""
        };
        var $element = $(element), element = element;
        var plugin = this;
        plugin.settings = {}

        /* init function */
        plugin.init = function () {
            plugin.settings = $.extend({}, defaults, options);
            $element.catcompleteGlobalSearch({
                source: function (request, response) {
                    $.getJSON("/api/custom/globalsearch/typeahead", { currentTime: $.now(), term: encodeURIComponent(request.term) },
                              response);
                },
                minLength: 2,
                select: function (event, ui) {
                    if (ui.item && ui.item.Title.toLowerCase() === options ? options.viewAllLabel.toLowerCase() : "view all") {
                        $("#txtSiteSearch").val(ui.item.Term);
                    }
                    if (ui.item) {
                        window.location = decodeURIComponent(ui.item.Url);
                        return false;
                    }
                    return false;
                },
                search: function () {
                    $('ul.ui-autocomplete').scrollTop(0);
                },
                open: function () {
                    $('ul.ui-autocomplete').css('width', $element.outerWidth() + 'px');
                    $('ul.ui-autocomplete').scrollTop(0);
                }
            });

        }


        plugin.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.globalAutoSuggest = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('globalAutoSuggest')) {
                var plugin = new $.globalAutoSuggest(this, options);
                $(this).data('globalAutoSuggest', plugin);
            }
        });
    }

}(jQuery));

/* Translations for Search Result */
var mlSearchFilterTranslations = function () {
    var _self = this;

    _self.trim = function (rawText) {
        var text = rawText.split('(')[0];
        if (text.indexOf(':') > 0) {
            text = text.split(':')[1];
        }
        
        return $.trim(text);
    }

    _self.init = function () {
        if ($("html").attr("lang") == "en" || $("#refineResults").length < 1) {
            return;
        }

        $.ajax({
            url: "/api/custom/searchresults/translations",
            data: {},
            type: "GET",
            dataType: "json",
            success: function (data) {
                if (data) {
                    var dictionary = [];

                    $.each(data.Translations, function (i, g) {
                        var key = g.Key.toUpperCase();
                        var value = g.Value.toUpperCase();
                        dictionary[key] = value;
                    });
                    
                    // category
                    _self.populate("section.by-category li a", dictionary);

                    // category filtered
                    _self.populate(".selectedFilterVal .grpcategory li", dictionary);

                    // subcategory
                    _self.populate("section.by-subcategory li a", dictionary);
                }
            },
            error: function (msg) {
                console.log(msg);
            }
        });
    }

    _self.populate = function (elm, dictionary) {
        $(elm).each(function (i) {
            var content = $(this).contents().first();
            var text = content.text();
            var trim = _self.trim(text);
            var translated = dictionary[trim.toUpperCase()];
            
            if (translated && translated.length > 0) {
                var newText = text.replace(trim, translated);
                content.replaceWith(newText);
            }
        });
    }
}

$(document).ready(function () {

    $("#txtSiteSearch").keypress(function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            var searchStringValue = $.trim($(this).val());
            var searchResultsPage = $(this).closest("form").attr("action");
            if (searchStringValue != "") {
                // var searchPageUrl=<%=SitecoreLibrary.GetItemUrl(ARMItempath.GlobalSearchPageItemId)%>;
                window.location = searchResultsPage + "?SearchString=" + encodeURIComponent(searchStringValue) + "&source=globalautosuggest";
                $('#txtGlobalSearch').val = decodeURIComponent($.urlParam('SearchString'));
                return false;
            } else {
                window.location = searchResultsPage + "?SearchString=" + encodeURIComponent('*') + "&source=globalautosuggest";
                return false;
            }
        }
    });

    $("#txtGlobalSearch").keypress(function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            var searchStringValue = $.trim($(this).val());
            var searchResultsPage = $(this).closest("form").attr("action");
            if (searchStringValue != "") {
                // "<%=SitecoreLibrary.GetItemUrl(ARMItempath.GlobalSearchPageItemId)%>?GlobalSearchString=" + searchStringValue);
                window.location = searchResultsPage + "?SearchString=" + encodeURIComponent(searchStringValue);
                $('#txtGlobalSearch').val = decodeURIComponent($.urlParam('SearchString'));
            } else {
                window.location = searchResultsPage + "?SearchString=" + encodeURIComponent('*');
                return false;
            }
        }
    });

    $(".btnSiteSearch").click(function (event) {
        var searchStringValue = $("#txtSiteSearch").val();
        var label = $("#txtSiteSearch").data("label"); // hardcoded "Search" before
        var searchResultsPage = $(this).closest("form").attr("action");
        if (searchStringValue == "" || searchStringValue == label) {
            window.location = searchResultsPage + "?SearchString=" + encodeURIComponent('*') + "&source=globalautosuggest";
            if (typeof ga !== "undefined") {
                ga('send', 'event', 'Global Search', 'Click', "");
            }
            return false;
        }

        if (searchStringValue != "") {
            window.location = searchResultsPage + "?SearchString=" + encodeURIComponent(searchStringValue) + "&source=globalautosuggest";
            $('#txtGlobalSearch').val = decodeURIComponent($.urlParam('SearchString'));
            if (typeof ga !== "undefined") {
                ga('send', 'event', 'Global Search', 'Click', searchStringValue);
            }
        }
    });

    // initialize translations
    new mlSearchFilterTranslations().init();

});
$.urlParam = function (name) {
    var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
        return 0;
    }
    return results[1] || 0;
};

function HideAllSortDdl() {
    $("#generalOptionsDiv").addClass('hide');
    $("#peopleOptionsDiv").addClass('hide');
    $("#practiceOptionsDiv").addClass('hide');
    $("#firmOptionsDiv").addClass('hide');
    $("#thinkingOptionsDiv").addClass('hide');
    $("#thinkingSubsectionOptionsDiv").addClass('hide');
}




